import { ReactElement, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import CSS from 'csstype';

const transitionAnimation = keyframes`
  from { width: 100vw; }
  to { width: 0px; }
`;

type StripeProps = {
  startPosition: string;
  delay: number;
  height: number;
  color?: string;
  direction: 'normal' | 'reverse';
};

const Stripe = styled.div<StripeProps>`
  position: absolute;
  height: ${(props) => `${props.height}vh`};
  top: ${(props) => props.startPosition};
  right: 0;
  background-color: ${(props) =>
    props.color || props.theme.color.neutral.color00};
  animation-name: ${transitionAnimation};
  animation-delay: ${(props) => `${props.delay}s`};
  animation-duration: 8s;
  animation-iteration-count: 1;
  animation-direction: ${(props) => props.direction};
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 800ms;
`;

type TransitionProps = {
  direction: 'normal' | 'reverse';
  stripesQuantity?: number;
  backgroundColor?: CSS.Property.BackgroundColor;
  onAnimationEnd?: () => void;
};

const Transition = ({
  direction,
  stripesQuantity = 5,
  backgroundColor,
  onAnimationEnd,
}: TransitionProps) => {
  const stripes = useMemo<ReactElement[]>(() => {
    const stripeArray = [];
    for (let i = 0; i < stripesQuantity; i++) {
      stripeArray.push(
        <Stripe
          key={i}
          color={backgroundColor}
          height={100 / stripesQuantity}
          startPosition={`${(100 / stripesQuantity) * i}vh`}
          delay={(1 / stripesQuantity) * i}
          direction={direction}
          onAnimationEnd={
            i === stripesQuantity - 1 ? onAnimationEnd : undefined
          }
        />
      );
    }
    return stripeArray;
  }, [stripesQuantity, backgroundColor]);

  return <>{stripes}</>;
};

export const OpenTransition = (props: Omit<TransitionProps, 'direction'>) => (
  <Transition direction='normal' {...props} />
);

export const CloseTransition = (props: Omit<TransitionProps, 'direction'>) => (
  <Transition direction='reverse' {...props} />
);
