import { useCallback, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import linkedInLogo from '../assets/img/linkedin_logo.png';
import { Title, Text, Caption } from '../design';
import { OpenTransition, CloseTransition } from '../design';

const App = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.brand.primary};
  color: ${(props) => props.theme.color.neutral.color00};
`;

const Body = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  min-height: 100vh;
  position: relative;
`;

const FullScreenCard = styled.div`
  margin: auto;
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.neutral.color00};
  margin: 8px 0;
`;

const Logo = styled.img`
  width: 24px;
`;

const theme = {
  color: {
    neutral: {
      color00: 'white',
      color100: 'black',
    },
    brand: {
      primary: '#007cff',
      secondary: '#EA2B1F',
    },
  },
};

export default function Home() {
  const [pageState, setPageState] = useState<'open' | 'closed'>('closed');

  const changePageState = useCallback(() => {
    setPageState((pageState) => (pageState === 'open' ? 'closed' : 'open'));
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <App>
        <Body>
          <FullScreenCard>
            <Text>I&apos;m</Text>
            <Title size='lg'>Victor Accarini</Title>
            <Text>a Software Developer.</Text>
            <Divider />
            <Caption>
              <a href='https://www.linkedin.com/in/victor-accarini'>
                <Logo src={linkedInLogo} alt='LinkedIn logo' />
              </a>
            </Caption>
          </FullScreenCard>
        </Body>
        {pageState === 'closed' && (
          <OpenTransition
            stripesQuantity={5}
            onAnimationEnd={changePageState}
          />
        )}
      </App>
    </ThemeProvider>
  );
}

/*


So finally:
font size = 14px
line height = (14*1.618) = 23px
width @ 50cpl = 50*(14/1.618) = 433px


        <p style={{ width: '70ch' }}>{text}</p>
const text = ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus ultricies
tristique nulla aliquet enim. Ullamcorper malesuada proin libero nunc consequat
interdum varius sit amet. Placerat duis ultricies lacus sed. Nunc aliquet
bibendum enim facilisis gravida neque convallis a. Bibendum est ultricies
integer quis auctor elit sed vulputate. Risus in hendrerit gravida rutrum
quisque non tellus. Dui vivamus arcu felis bibendum ut. Arcu ac tortor dignissim
convallis aenean et tortor at risus. Eget nunc scelerisque viverra mauris in.
Sem integer vitae justo eget magna. Donec enim diam vulputate ut pharetra. Nulla
pellentesque dignissim enim sit amet venenatis. Faucibus et molestie ac feugiat
sed lectus vestibulum.

Velit laoreet id donec ultrices. Felis eget nunc lobortis mattis aliquam
faucibus purus in massa. Eros donec ac odio tempor orci dapibus. Porttitor leo a
diam sollicitudin tempor id eu. Feugiat sed lectus vestibulum mattis ullamcorper
velit sed. Orci dapibus ultrices in iaculis nunc sed augue lacus viverra.
Viverra tellus in hac habitasse platea. Egestas erat imperdiet sed euismod nisi.
Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium
vulputate. Morbi quis commodo odio aenean sed adipiscing diam donec. Id cursus
metus aliquam eleifend mi in nulla. Diam in arcu cursus euismod quis.

Donec massa sapien faucibus et molestie ac feugiat sed lectus. Potenti nullam ac
tortor vitae purus. Magnis dis parturient montes nascetur ridiculus mus mauris.
Nulla porttitor massa id neque aliquam. Ipsum consequat nisl vel pretium lectus
quam. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Amet
commodo nulla facilisi nullam vehicula ipsum. Nisi est sit amet facilisis magna
etiam tempor orci eu. In fermentum posuere urna nec tincidunt praesent semper.
Gravida dictum fusce ut placerat orci nulla pellentesque dignissim enim. Urna
molestie at elementum eu facilisis sed. Posuere ac ut consequat semper viverra
nam libero. Egestas pretium aenean pharetra magna ac. Aliquam ut porttitor leo a
diam sollicitudin tempor id eu.

Felis imperdiet proin fermentum leo vel orci porta non pulvinar. Eu tincidunt
tortor aliquam nulla facilisi cras fermentum odio eu. Ultricies integer quis
auctor elit sed. Nisl nisi scelerisque eu ultrices vitae auctor. A iaculis at
erat pellentesque adipiscing commodo elit. Integer enim neque volutpat ac
tincidunt vitae semper. At urna condimentum mattis pellentesque id nibh. Sed
blandit libero volutpat sed. Neque aliquam vestibulum morbi blandit cursus risus
at ultrices mi. Semper auctor neque vitae tempus quam pellentesque nec. Auctor
augue mauris augue neque gravida in fermentum et sollicitudin. Sed elementum
tempus egestas sed. Convallis a cras semper auctor neque vitae. Integer vitae
justo eget magna fermentum iaculis. Magna fermentum iaculis eu non diam
phasellus vestibulum lorem sed. Quisque egestas diam in arcu cursus.

Ornare lectus sit amet est placerat in. Netus et malesuada fames ac turpis
egestas. Posuere lorem ipsum dolor sit amet consectetur. Placerat vestibulum
lectus mauris ultrices eros in. Consequat id porta nibh venenatis cras sed
felis. Ante in nibh mauris cursus mattis. Euismod lacinia at quis risus sed. Est
ante in nibh mauris cursus. Pretium nibh ipsum consequat nisl vel pretium
lectus. Ut morbi tincidunt augue interdum velit euismod in pellentesque massa.
Aliquet eget sit amet tellus cras.`;
*/
