import styled from 'styled-components';

type TitleProps = {
  size: 'sm' | 'md' | 'lg';
};

const TitleSize = {
  sm: '2rem',
  md: '3rem',
  lg: '4rem',
};

export const Title = styled.h1<TitleProps>`
  font-size: ${({ size }) => TitleSize[size]};
  font-weight: 800;
  margin: 0.5rem 0.25rem 0;
`;

export const Text = styled.p`
  font-size: 1.5rem;
`;

export const Caption = styled.p`
  margin: 0.1rem;
  font-size: 0.7rem;
`;
